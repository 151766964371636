import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LayoutWrapper from 'components/LayoutWrapper';
import Content from 'components/Template';
import { CMSWrapper } from 'configs/globalStyle';

const Policy = (): FC => {
  const data = useStaticQuery(graphql`
    {
      allPrismicTermsAndConditionsForVeterinarians {
        edges {
          node {
            data {
              content {
                raw
              }
              title {
                raw
              }
            }
          }
        }
      }
    }
  `);

  const document =
    data.allPrismicTermsAndConditionsForVeterinarians.edges[0].node.data;
  return (
    <LayoutWrapper>
      <CMSWrapper>
        <Content data={document} />
      </CMSWrapper>
    </LayoutWrapper>
  );
};

export default Policy;
